var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card',{attrs:{"header":"Search"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('month-filter',{attrs:{"month":_vm.inpoCriteria.dateRange},on:{"update:month":function($event){return _vm.$set(_vm.inpoCriteria, "dateRange", $event)}}})],1),_c('b-col',{staticClass:"mt-1 mt-md-0",attrs:{"md":"2"}},[_c('search-button',{on:{"refresh":_vm.refreshTable}})],1)],1)],1)],1)],1),_c('div',{staticClass:"text-right mb-1"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.removeAllPrayers}},[_vm._v(" Delete all prayers ")])],1),_c('b-row',[_c('b-col',[_c('prayers-table',_vm._b({ref:"parentTable"},'prayers-table',{
          getPrayers: _vm.getPrayers,
          entityId:_vm.inpoCriteria.id,
          updateDatePrayer: _vm.updateDatePrayer,
          setEntityIdInDayPrayerTimes: _vm.setEntityIdInDayPrayerTimes,
          deletePrayer: _vm.deletePrayer,
          pagination: _vm.pagination,
        },false))],1)],1),_c('pagination',{attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:currentPage":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.pagination, "currentPage", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }