import { ref } from '@vue/composition-api'

export default function paginationData() {
  const pagination = ref(
    {
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
    },
  )
  return { pagination }
}
