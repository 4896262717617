<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="getPrayers"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      bordered
      hover
      empty-text="No Prayers found"
      show-empty
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ 'min-width': '10em' }"
        >
      </template>
      <template #cell(label)>
        <div class="text-center">
          <div>Azan</div>
          <hr>
          <div>Eqama</div>
        </div>
      </template>
      <template #cell(fajr)="{item}">
        <b-form-group>
          <flat-pickr
            v-model="item.fajr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
        <b-form-group>
          <flat-pickr
            v-model="item.iqamah_fajr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
      </template>
      <template #cell(shurouk)="{item}">
        <flat-pickr
          v-model="item.shurouk"
          class="form-control"
          :config="{
            ...timePickerConfig,
            minDate:item.fajr,
            maxDate:item.duhr,
          }"
        />
      </template>
      <template #cell(duhr)="{item}">
        <b-form-group>
          <flat-pickr
            v-model="item.duhr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
        <b-form-group>
          <flat-pickr
            v-model="item.iqamah_duhr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
      </template>
      <template #cell(asr)="{item}">
        <b-form-group>
          <flat-pickr
            v-model="item.asr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
        <b-form-group>
          <flat-pickr
            v-model="item.iqamah_asr"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
      </template>
      <template #cell(maghrib)="{item}">
        <b-form-group>
          <flat-pickr
            v-model="item.maghrib"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
        <b-form-group>
          <flat-pickr
            v-model="item.iqamah_maghrib"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
      </template>
      <template #cell(isha)="{item}">
        <b-form-group>
          <flat-pickr
            v-model="item.isha"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
        <b-form-group>
          <flat-pickr
            v-model="item.iqamah_isha"
            class="form-control"
            :config="timePickerConfig"
          />
        </b-form-group>
      </template>
      <template #cell(action)="{item}">
        <b-button
          variant="primary"
          class="mb-1"
          @click="updateDatePrayer(item)"
        >
          <feather-icon
            icon="SaveIcon"
          />
          <span>Save</span>
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="deletePrayer(item.id)"
        >
          <feather-icon
            icon="XOctagonIcon"
          />
          <span>Delete</span>
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'PrayersTable',
  components: {
    flatPickr,
  },
  props: {
    getPrayers: { type: Function, default: () => [] },
    entityId: { type: Number, default: null },
    updateDatePrayer: { type: Function, default: () => null },
    setEntityIdInDayPrayerTimes: { type: Function, default: () => null },
    deletePrayer: { type: Function, default: () => null },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      selectedDatePrayerTimes: null,
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        altInput: true,
        altFormat: 'h:i K',
      },
      tableColumns: [
        {
          key: 'day', formatter: v => this.$moment(v).format('ddd,D MMM'),
        },
        { key: 'label' },
        { key: 'fajr' },
        { key: 'shurouk' },
        { key: 'duhr' },
        { key: 'asr' },
        { key: 'maghrib' },
        { key: 'isha' },
        { key: 'action' },
      ],
    }
  },
}
</script>
<style lang="scss">

</style>
