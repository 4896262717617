<template>
  <div>
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="6">
              <month-filter :month.sync="inpoCriteria.dateRange" />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div
      class="text-right mb-1"
    >
      <b-button
        variant="danger"
        @click="removeAllPrayers"
      >
        Delete all prayers
      </b-button>
    </div>
    <b-row>
      <b-col>
        <prayers-table
          ref="parentTable"
          v-bind="{
            getPrayers,
            entityId:inpoCriteria.id,
            updateDatePrayer,
            setEntityIdInDayPrayerTimes,
            deletePrayer,
            pagination,
          }"
        />
      </b-col>
    </b-row>
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import PrayersTable from '@/common/components/Prayers/PrayersTable.vue'
import MonthFilter from '@/common/components/Prayers/MonthFilter.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'PrayersList',
  components: {
    MonthFilter,
    SearchButton,
    PrayersTable,
    Pagination,
  },
  data() {
    return {
      inpoCriteria: {
        id: this.$store.getters['mainEntity/getEntityId'],
        dateRange: [
          this.$moment().startOf('month').format('YYYY-MM-DD'),
          this.$moment().endOf('month').format('YYYY-MM-DD'),
        ],
      },
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert, confirmOperation } = handleAlerts()
    return {
      pagination, successfulOperationAlert, confirmOperation,
    }
  },
  methods: {
    getPrayers() {
      return this.$portalUsers.get(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayer`,
        {
          params: {
            entityId: this.$store.getters['mainEntity/getEntityId'],
            from: this.inpoCriteria.dateRange[0],
            to: this.inpoCriteria.dateRange[1],
            page: this.pagination.currentPage,
          },
        })
        .then(res => {
          const prayersTimes = res.data.data
          this.pagination.totalRows = res.data.pagination.total
          return prayersTimes || []
        })
        .catch(() => [])
    },
    updateDatePrayer(dayPrayersTimes) {
      const newPrayerTimes = this.setEntityIdInDayPrayerTimes(dayPrayersTimes)

      this.$portalUsers.post(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayer`, newPrayerTimes).then(() => {
        this.successfulOperationAlert('Prayers Times are updated successfully')
      })
    },
    setEntityIdInDayPrayerTimes(dayPrayersTimes) {
      dayPrayersTimes.entityId = this.entityId
      // eslint-disable-next-line no-underscore-dangle
      dayPrayersTimes.prayerId = dayPrayersTimes._id
      return dayPrayersTimes
    },
    deletePrayer(id) {
      this.confirmOperation().then(() => {
        this.$portalUsers.delete(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayer/${id}`)
          .then(() => {
            this.successfulOperationAlert('Prayer is deleted successfully')
            this.refreshTable()
          })
      })
    },
    removeAllPrayers() {
      this.confirmOperation('Are you sure you want to delete all prayers?').then(() => {
        this.$portalUsers.delete(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayers`).then(() => {
          this.successfulOperationAlert('All prayers are deleted successfully')
          this.refreshTable()
        })
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
