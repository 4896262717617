<template>
  <b-form-group>
    <b-input-group>
      <flat-pickr
        v-model="monthAndYear"
        class="form-control"
        :config="{plugins:plugins, disableMobile: true}"
        @on-close="setDateRange"
      />
      <b-input-group-append>
        <b-button
          variant="danger"
          size="sm"
          @click="resetDateRange"
        >
          Clear
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  name: 'MonthFilter',
  components: {
    flatPickr,
  },
  props: {
    month: { type: Array, default: null },
  },
  data() {
    return {
      monthAndYear: new Date(),
      plugins: [
        new MonthSelectPlugin({
          shorthand: true, // defaults to false
          theme: localStorage.getItem('vuexy-skin') ?? 'light', // defaults to "light"
        }),
      ],
    }
  },
  methods: {
    setDateRange(selectedMonth) {
      const startDate = this.$moment(selectedMonth[0]).startOf('month').format('YYYY-MM-DD')
      const endDate = this.$moment(selectedMonth[0]).endOf('month').format('YYYY-MM-DD')
      this.$emit('update:month', [startDate, endDate])
    },
    resetDateRange() {
      this.$emit('update:month', [null, null])
      this.monthAndYear = ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
