var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-table',{ref:"table",attrs:{"no-provider-sorting":true,"items":_vm.getPrayers,"fields":_vm.tableColumns,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"responsive":"","bordered":"","hover":"","empty-text":"No Prayers found","show-empty":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ 'min-width': '10em' })})})}},{key:"cell(label)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',[_vm._v("Azan")]),_c('hr'),_c('div',[_vm._v("Eqama")])])]},proxy:true},{key:"cell(fajr)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.fajr),callback:function ($$v) {_vm.$set(item, "fajr", $$v)},expression:"item.fajr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamah_fajr),callback:function ($$v) {_vm.$set(item, "iqamah_fajr", $$v)},expression:"item.iqamah_fajr"}})],1)]}},{key:"cell(shurouk)",fn:function(ref){
var item = ref.item;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":Object.assign({}, _vm.timePickerConfig,
          {minDate:item.fajr,
          maxDate:item.duhr})},model:{value:(item.shurouk),callback:function ($$v) {_vm.$set(item, "shurouk", $$v)},expression:"item.shurouk"}})]}},{key:"cell(duhr)",fn:function(ref){
        var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.duhr),callback:function ($$v) {_vm.$set(item, "duhr", $$v)},expression:"item.duhr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamah_duhr),callback:function ($$v) {_vm.$set(item, "iqamah_duhr", $$v)},expression:"item.iqamah_duhr"}})],1)]}},{key:"cell(asr)",fn:function(ref){
        var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.asr),callback:function ($$v) {_vm.$set(item, "asr", $$v)},expression:"item.asr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamah_asr),callback:function ($$v) {_vm.$set(item, "iqamah_asr", $$v)},expression:"item.iqamah_asr"}})],1)]}},{key:"cell(maghrib)",fn:function(ref){
        var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.maghrib),callback:function ($$v) {_vm.$set(item, "maghrib", $$v)},expression:"item.maghrib"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamah_maghrib),callback:function ($$v) {_vm.$set(item, "iqamah_maghrib", $$v)},expression:"item.iqamah_maghrib"}})],1)]}},{key:"cell(isha)",fn:function(ref){
        var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.isha),callback:function ($$v) {_vm.$set(item, "isha", $$v)},expression:"item.isha"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamah_isha),callback:function ($$v) {_vm.$set(item, "iqamah_isha", $$v)},expression:"item.iqamah_isha"}})],1)]}},{key:"cell(action)",fn:function(ref){
        var item = ref.item;
return [_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateDatePrayer(item)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_c('span',[_vm._v("Save")])],1),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deletePrayer(item.id)}}},[_c('feather-icon',{attrs:{"icon":"XOctagonIcon"}}),_c('span',[_vm._v("Delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }